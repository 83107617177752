export const lightTheme = {
  isDarkMode: false,
  // 기본 색상
  body: "#FFF",
  containerBorder: "#e0e0e0",
  text: "#363537",
  background: "#97BFBE",
  topColor: "#97BFBE",
  switchBackground: "#97bfbe",
  switchActiveBackground: "#97bfbe",
  switchActiveCircle: "#fff",
  buttonBackground: "#fff",
  buttonText: "#97BFBE",
  buttonBorder: "#97BFBE",
  buttonHoverBackground: "#97BFBE",
  buttonHoverText: "#fff",
  containerBackground: "white",
  inputBackground: "#ffffff",
  inputBorder: "#ced4da",
  inputPlaceholder: "#BDBDBD",
  inputFocusBorder: "#80bdff",
  inputFocusShadow: "rgba(0, 123, 255, 0.25)",
  borderColor: "#eee",
  deleteButtonBackground: "#dc3545",
  deleteButtonText: "#ffffff",
  deleteButtonBorder: "#dc3545",
  deleteButtonHoverBackground: "#ff4d4f",
  deleteButtonHoverText: "#ffffff",
  successBg: "#e6f4ea",
  warningBg: "#fff3e0",
  textSecondary: "#6c757d",
  success: "#28a745",
  warning: "#ffc107",
  headerBackground: "#f8f9fa",
  backgroundSecondary: "#f8f9fa",
  profileBackground: "#ffffff",

  // 모달 색상
  cancleButtonBackground: "grey",
  cancleButtonText: "#fff",
  saveButtonBackground: "#97BFBE",
  saveButtonText: "#fff",

  // 리포트 색상
  reportButton: "#007BFF",
  reportContainer: "#fff",
  reportMenu: "#007BFF",
  boxShadow: "rgba(0, 0, 0, 0.1)",
  exampleContainer: "#f0f8ff",
  exampleTitle: "#007bff",
  exampleSubtitle: "#333",
  exampleText: "#555",
  exampleSection: "#f0f8ff",
  exampleSectionTitle: "#007bff",
  exampleSectionText: "#555",
  exampleSectionBackground: "#f0f8ff",

  // 아기똥 색상
  calendarWeekend: "#Fe2e2e",
  calendarWeekendText: "#F5A9A9",
  poopDetail: "grey",
  calendarNav: "#007BFF",

  // 영양성분 색상
  milkDiet: "#1e88e5",
  solidDiet: "#ad1457",
  milkNoticeContainer: "#fff1cd",

  // MilkDiet 차트 색상
  chartGrid: "#fce4ec",
  chartAxis: "#c48b9f",
  chartText: "#880e4f",
  chartLine1: "#f06292",
  chartLine2: "#4a148c",
  chartDot: "#ad1457",
  chartReference: "#ff5722",

  checkboxBorder: "#007bff",
  checkboxCheckedBackground: "#007bff",
  checkboxCheckedColor: "white",
  vaccinationItemBackground: "#f8f9fa",
  vaccinationItemCheckedBackground: "#f0f8ff",

  // 기록 색상
  dateRangeText: "#495057",
  circleBackground: "#FFFFFF",
  lineColor: "#FFFFFF",
  chatbotBorder: "#ddd",

  // TopClock 컴포넌트를 위한 새로운 색상
  topClockBackground: "#ffffff",
  topClockBorder: "#ced4da",
  topClockDateText: "#6c757d",
  topClockTimeText: "#363537",
  topClockEndTimeButton: "#97BFBE",
  topClockEndTimeButtonText: "#ffffff",
  topClockAdjustmentButton: "#97BFBE",
  topClockEditIcon: "#6c757d",
  topClockAdjustmentButtonBackground: "#ffffff",
  topClockAdjustmentButtonText: "#97BFBE",

  timePickerBackground: "#ffffff",
  timePickerText: "black",
  timePickerBorder: "#97bfbe",
  timePickerActiveText: "#000000",
  timePickerInactiveText: "#999999",
  timePickerSeparator: "#000000",
  timePickerButton: "white",
  timePickerButtonText: "#97bfbe",

  wideButtonBackground: "#fff",
  wideButtonBackgroundActive: "#97BFBE",
  wideButtonText: "#97BFBE",
  wideButtonTextActive: "#fff",

  diaperButtonBackground: "#fff",
  diaperButtonBackgroundActive: "#97BFBE",
  diaperButtonText: "#97BFBE",
  diaperButtonTextActive: "#fff",

  modalOverlay: "rgba(0, 0, 0, 0.5)",
  modalBackground: "#FFFFFF",
  calendarRangeBackground: "#e8f0fe",
  calendarSelectedBackground: "#1a73e8",
  calendarSelectedText: "#FFFFFF",
  calendarTodayBorder: "#000000",

  photoContainerBackground: "#FFFFFF",
  photoTextColor: "#666",

  statisticsDetail: "#666",
  tutorialButton: "#4a90e2",

  themeSelectorBg: "#f0f0f0",
  activeThemeOptionBg: "#ffffff",
  activeThemeOptionColor: "#000000",
  inactiveThemeOptionColor: "#666666",

  // 통계 색상
  weeklyPatternCell: "#f8f8f8",
  weeklyPatternGrid: "#eee",
  chatbotUserBackground: "#007bff",
  chatbotChatbotBackground: "#FFFFFF",
  chatbotUserText: "#fff",
  chatbotChatbotText: "#000",
  chatbotSendButton: "#007bff",

  // 파이 차트 색상 추가
  pieChartBackground: "#f0f0f0",
  pieChartBorder: "#ccc",
  pieChartInner: "white",
  pieChartText: "#333",
  pieChartLine: "#ccc",
  pieChartOther: "#CCCCCC",

  // 백분위 색상
  rankLine1: "#FFD1DC",
  rankLine2: "#87CEEB",

  seenNoticeBg: "rgba(0, 0, 0, 0.05)", // 연한 회색

  ticketContainerBackground: "#ffffff",
  ticketContainerShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  ticketContainerBorder: "#e5e7eb",
  ticketLabelColor: "#6b7280",
  ticketCountColor: "#3b82f6",

  eventTagBackground: "#3b82f6",
  eventTagColor: "#ffffff",
  eventTagShadow: "0 2px 4px rgba(59, 130, 246, 0.3)",
};

export const darkTheme = {
  isDarkMode: true,
  // 기본 색상
  body: "#363537",
  containerBorder: "#4a5568",
  text: "#FAFAFA",
  background: "#4A5D5C",
  topColor: "#4A5D5C",
  switchBackground: "#4a5d5c",
  switchActiveBackground: "#4a5d5c",
  switchActiveCircle: "#fff",
  buttonBackground: "#4A5D5C",
  buttonText: "#FAFAFA",
  buttonBorder: "#FAFAFA",
  buttonHoverBackground: "#FAFAFA",
  buttonHoverText: "#4A5D5C",
  containerBackground: "#363537",
  inputBackground: "#1e2a38",
  inputBorder: "#4a5568",
  inputPlaceholder: "#6C6C6C",
  inputFocusBorder: "#63b3ed",
  inputFocusShadow: "rgba(66, 153, 225, 0.25)",
  borderColor: "#444",
  deleteButtonBackground: "#c82333",
  deleteButtonText: "#ffffff",
  deleteButtonBorder: "#c82333",
  deleteButtonHoverBackground: "#800f19",
  deleteButtonHoverText: "#ffffff",
  successBg: "#1e4620",
  warningBg: "#3f2f00",
  textSecondary: "#a0aec0",
  success: "#28a745",
  warning: "#ffc107",
  headerBackground: "#2c3e50",
  backgroundSecondary: "#2c3e50",
  profileBackground: "#2c3e50",
  // 모달 색상
  cancleButtonBackground: "#4A5D5C",
  cancleButtonText: "#FAFAFA",

  // 리포트 색상
  reportButton: "#fafafa",
  reportContainer: "#4A4A4A",
  reportMenu: "#FFFF00",
  boxShadow: "rgba(255, 255, 255, 0.1)",
  exampleContainer: "#363537",
  exampleTitle: "#007bff",
  exampleSubtitle: "#333",
  exampleText: "#fff",
  exampleSection: "#FFFFE0",
  exampleSectionTitle: "#FFFF00",
  exampleSectionText: "#fff",
  exampleSectionBackground: "#363537",

  // 아기똥 색상
  calendarWeekend: "#FFBF00",
  calendarWeekendText: "#F5DA81",
  poopDetail: "white",
  calendarNav: "#FFBF00",

  // 영양성분 색상
  milkDiet: "#FFBF00",
  solidDiet: "#ad1457",
  milkNoticeContainer: "#4A5D5C",

  // MilkDiet 차트 색상
  chartGrid: "#37474f",
  chartAxis: "#b0bec5",
  chartText: "#eceff1",
  chartLine1: "#81d4fa",
  chartLine2: "#4fc3f7",
  chartDot: "#29b6f6",
  chartReference: "#ff8a65",

  checkboxBorder: "#4a5d5c",
  checkboxCheckedBackground: "#4a5d5c",
  checkboxCheckedColor: "#fafafa",
  vaccinationItemBackground: "#2c3e50", // 약간 밝은 네이비 블루
  vaccinationItemCheckedBackground: "#34495e", // 더 밝은 네이비 블루

  // 기록 색상
  dateRangeText: "#grey",
  circleBackground: "#4C6479",
  lineColor: "#4C6479",
  chatbotBorder: "#444444",

  // TopClock 컴포넌트를 위한 수정된 다크 모드 색상
  topClockBackground: "#2c3e50",
  topClockBorder: "#4a5568",
  topClockDateText: "#fafafa", // 날짜 선택 텍스트 색상을 더 밝게 변경
  topClockTimeText: "#fafafa",
  topClockEndTimeButton: "#6B8E23", // 완료 시간 버튼 배경색을 더 밝고 구분되는 색상으로 변경
  topClockEndTimeButtonText: "#fafafa",
  topClockAdjustmentButton: "#4A5D5C",
  topClockEditIcon: "#a0aec0",
  topClockAdjustmentButtonBackground: "#4A5D5C",
  topClockAdjustmentButtonText: "#fafafa",

  timePickerBackground: "#2c3e50",
  timePickerText: "#fafafa",
  timePickerBorder: "#4a5568",
  timePickerActiveText: "#ffffff",
  timePickerInactiveText: "#a0aec0",
  timePickerSeparator: "#fafafa",
  timePickerButton: "#6B8E23",
  timePickerButtonText: "#fafafa",

  wideButtonBackground: "#2c3e50",
  wideButtonBackgroundActive: "#4A5D5C",
  wideButtonText: "#a0aec0",
  wideButtonTextActive: "#fafafa",

  diaperButtonBackground: "#fff",
  diaperButtonBackgroundActive: "#4A5D5C",
  diaperButtonText: "#4A5D5C",
  diaperButtonTextActive: "#fff",

  modalOverlay: "rgba(0, 0, 0, 0.7)",
  modalBackground: "#2c3e50",
  calendarRangeBackground: "#4A5D5C",
  calendarSelectedBackground: "#6B8E23",
  calendarSelectedText: "#FFFFFF",
  calendarTodayBorder: "#FFFFFF",

  photoContainerBackground: "#2c3e50",
  photoTextColor: "#a0aec0",

  statisticsDetail: "#999",
  tutorialButton: "#007bff",

  themeSelectorBg: "#2a2a2a",
  activeThemeOptionBg: "#2c3e50",
  activeThemeOptionColor: "#ffffff",
  inactiveThemeOptionColor: "#999999",

  // 통계 색상
  weeklyPatternCell: "#424544",
  weeklyPatternGrid: "#505150",
  chatbotUserBackground: "#007bff",
  chatbotChatbotBackground: "#FFFFFF",
  chatbotUserText: "#fff",
  chatbotChatbotText: "#000",

  // 파이 차트 색상 추가
  pieChartBackground: "#2c3e50",
  pieChartBorder: "#4a5568",
  pieChartInner: "#363537",
  pieChartText: "#fafafa",
  pieChartLine: "#4a5568",
  pieChartOther: "#4a5568",

  // 백분위 색상
  rankLine1: "#C71585",
  rankLine2: "#4682B4",

  seenNoticeBg: "rgba(255, 255, 255, 0.05)", // 연한 흰색

  ticketContainerBackground: "#2c3e50",
  ticketContainerShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  ticketContainerBorder: "#4a5568",
  ticketLabelColor: "#a0aec0",
  ticketCountColor: "#60a5fa",

  eventTagBackground: "#60a5fa",
  eventTagColor: "#ffffff",
  eventTagShadow: "0 2px 4px rgba(96, 165, 250, 0.3)",
};
